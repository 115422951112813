<footer class="footer mt-auto" [ngClass]="{'wide': getFooterContext() === 'Wide'}" *ngIf="getFooterContext()">
	<div [ngClass]="{'container': getFooterContext() === 'Default',
                    'container-fluid': getFooterContext() === 'Wide'}">
		<div class="row d-flex justify-content-between">
			<div class="copyright">
				© {{currentYear}} Unnanu Talent.
			</div>
			<div class="footer-links">
				<a href="{{unnanu_home_page}}/tos" target="_blank">Terms</a> · <a
					href="{{unnanu_home_page}}/privacy" target="_blank">Privacy</a> · <a
					href="{{unnanu_home_page}}/cookie" target="_blank">Cookie</a> · <a
					href="{{unnanu_home_page}}/support" target="_blank">FAQ</a>
			</div>
		</div>
	</div>
</footer>
