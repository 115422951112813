import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContextService } from '../core/services/context.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public unnanu_landing_page = environment.urls.hire_landing_page;
	public unnanu_home_page = environment.urls.home_page;
	public currentYear: number = new Date().getFullYear();

	constructor(private contextService: ContextService) { }

	ngOnInit(): void {
	}

	getFooterContext() {
		return this.contextService.getFooterContext();
	}

}
